/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "E& UAE and Corning Introduce Fibre-to-the-Yacht Connectivity at Yas Marina"), "\n", React.createElement(_components.h2, null, "Overview"), "\n", React.createElement(_components.p, null, "E& UAE and Corning have collaborated to introduce Fibre-to-the-Yacht (FTTY) connectivity at Yas Marina, offering high-speed internet for yacht owners. With this partnership, 138 yachts have been connected with Corning's fiber optic cables, providing exceptional speed, unparalleled reliability, and seamless online connectivity for work, entertainment, and communication. The aim of this collaboration is to future-proof network infrastructure and stay at the forefront of technological advancements in the yachting industry."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "References:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.thefastmode.com/technology-solutions/35437-e-corning-collaborate-to-unveil-fibre-to-the-yacht-ftty"
  }, "The Fast Mode")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://telecomtalk.info/euae-corning-fibre-to-the-yacht-connectivity/943472/"
  }, "TelecomTalk")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zawya.com/en/press-release/companies-news/e-and-uae-and-corning-unveil-transformative-fibre-to-the-yacht-ftty-collaboration-rgi684lj"
  }, "Zawya")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.telecompaper.com/news/e-and-corning-launch-fibre-to-the-yacht-at-yas-marina--1495171"
  }, "Telecompaper")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
